<template>
        <v-form ref="form" class="d-flex flex-column">
            <v-row>
                <v-col cols="5">
                    <v-text-field
                    type="number"
                    v-model.trim="wData.sum"
                    label="Сумма *"
                    dense
                    outlined
                    :rules="[(v) => !!v || 'Поле не должно быть пустым']"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="5">
                    <v-checkbox v-if="!project && !oldWData" v-model="withoutProject" label="Без проекта"></v-checkbox>

                    <template v-if='!withoutProject || oldWData'>
                        <AddProjectDialog 
                            v-if="!project" 
                            title="Выбрать проект" 
                            :btnText="projectInfo ? 'Изменить проект' : 'Добавить проект'" 
                            
                            @selectProject="selectProject"
                            :disabled="!!oldWData" 
                        ></AddProjectDialog>

                        <v-text-field   
                        class="mt-4"  
                            v-if="!!projectInfo"                   
                            v-model.trim="projectInfo.shortName"
                            label="Проект *"
                            dense
                            outlined
                            :rules="[(v) => !!v || 'Поле не должно быть пустым. Введите короткое название проекта.']"
                            readonly
                        ></v-text-field>
                    </template>
                </v-col>
            </v-row>
            
            <v-row>
                <v-col cols="5">
                    <h3 class="mb-2">Тип проводки</h3>
                    <v-radio-group v-model="wData.type" :rules="[(v) => !!v || 'Поле не должно быть пустым']">
                        <v-radio
                            v-for="(item, i) in typesItems"
                            :key="i"
                            :label="item.label"
                            :value="item.value"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="5" class="mt-3">
                    <v-select
                    label="Способ оплаты*"
                    :items="paymentMethods"
                    item-text="text"
                    item-value="id"
                    return-object
                    v-model="wData.paymentMethod"
                    dense
                    outlined
                    required
                    :rules="[(v) => !!v || 'Поле не должно быть пустым']"
                    ></v-select>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="5" class="mt-3">
                    <date-field label="Дата оплаты *" v-model.trim="wData.paymentDate" :rules="[(v) => !!v || 'Поле обязательно для заполнения']">

                    </date-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="5">
                    <v-select
                    v-model="wData.budgetYear"
                    :disabled="!!projectInfo"
                    label="Год по бюджету *"
                    dense
                    outlined
                    :items="yearsList"
                    :rules="[(v) => !!v || 'Поле не должно быть пустым']"
                    ></v-select>
                </v-col>
            </v-row>

            <v-row>
          <v-col cols="5">
            <v-select
              label="Организация *"
              :rules="[(v) => !!v || 'Поле не должно быть пустым']"
              :items="ourOrgs"
              item-text="shortName"
              item-value="id"
              return-object
              v-model.trim="wData.organization"
              dense
              outlined
              clearable
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="5">
            <v-text-field
                label="Ответственный *"
                :value="wData.expert ? wData.expert.fullName : 'Никого не выбрано'"
                :rules="[(v) => !!v || 'Поле обязательно для заполнения']"
                dense
                outlined
                readonly
                    >
            </v-text-field>
          </v-col>
        </v-row>

            <v-row>
                <v-col cols="5">
                    <h3 class="mb-2">Доход / Расход *</h3>
                    <v-radio-group v-model="wData.typeDir" :rules="[(v) => !!v || 'Поле обязательно для заполнения']">
                        <v-radio
                            v-for="(item, i) in filteredTypeDirItems"
                            :key="i"
                            :label="item.title"
                            :value="item.value"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row> 

            <v-row >
                <v-col cols="5">
                    
                    <v-menu 
                        :close-on-content-click="false"
                        top
                        transition="slide-x-transition"
                        right
                        :offset-x="true">
                        <template v-slot:activator="{ on, attrs }">   
                            <v-text-field
                                label="Фонд *"
                                v-model="wData.fund.fund"
                                :rules="[(v) => !!v || 'Поле обязательно для заполнения']"
                                dense
                                outlined
                                readonly
                                v-on="on"
                                v-bind="attrs"
                                :disabled="!wData.typeDir"
                            ></v-text-field>
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                :disabled="!wData.typeDir"
                                style="margin-bottom: 20px"
                            >{{wData.fund.fund ? 'Изменить фонд и статью' : 'Выбрать фонд и статью'}}</v-btn>                                     
                            <v-text-field
                                label="Статья *"
                                v-model="wData.article.article"
                                :rules="[(v) => !!v || 'Поле обязательно для заполнения']"
                                dense
                                outlined
                                readonly
                                v-on="on"
                                :disabled="!wData.typeDir"
                                v-bind="attrs"
                            ></v-text-field>
                        </template>
                        
                            <v-list>
                                <v-list-group no-action v-for="(fund, i) in filteredFundsByProject" :key="i">
                                    <template v-slot:activator>
                                        <v-list-item-title>{{ fund.fund }}</v-list-item-title>
                                    </template>
                                    <v-list-item @click="addFundAndArticle(fund.id, article.id)" v-for="article in getArticlesItems(fund.id)" :key="article.id" link >{{ article.article }}</v-list-item>
                                </v-list-group>
                            </v-list>
                    </v-menu>
                    
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="5">
                    <register-select-field
                        field-title="Контрагент"
                        dialog-title="Выбор контрагент"
                        :reg-config="counterpartiesRegisterConfig"
                        page-size="10"
                        default-tab="0"
                        :text-function="(item) => item.fullName || ''"
                        text-search-attribute="fullName"
                        :value-object="wData.contractor"
                        @select="wData.contractor = $event;"
                        @clear="wData.contractor = null;"
                        :not-null="wData.type === 'fact' && checkBindArticleByAgent(wData.article.article)"
                        >
                    </register-select-field>
                </v-col>
            </v-row>

            
            <v-row>
                <v-col cols="5">
                    <register-select-field
                        field-title="Договор"
                        dialog-title="Выбор договора"
                        :reg-config="contractsRegisterConfig"
                        page-size="10"
                        default-tab="0"
                        :text-function="(item) => item.contractNumber || ''"
                        text-search-attribute="theme"
                        :value-object="selectedContract"
                        @select="selectedContract = $event"
                        @clear="selectedContract = null"
                        :not-null="wData.type === 'fact' && checkBindArticleByContract(wData.article.article) && !project.noContractProject"
                        >
                    </register-select-field>
                </v-col>
            </v-row>
            
            <v-row>
                <v-col cols="5">
                    <v-select
                    v-model.trim="wData.accountNumber"
                    label="Номер счета"
                    dense
                    outlined
                    disabled
                    ></v-select>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="5">
                    <v-text-field
                        v-model.trim="wData.contribution"
                        label="Вклад"
                        dense
                        disabled
                        outlined
                    ></v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="5">
                    <v-textarea
                        v-model.trim="wData.note"
                        label="Примечание"
                        outlined
                        clearable
                    ></v-textarea>
                </v-col>
            </v-row>
            
            <v-row>
                <v-col cols="5">
                    <v-btn v-if="notSaveWiring" @click="handleAddWiring">Добавить проводку</v-btn> 
                    <v-btn v-else-if="oldWData" @click="handleUpdateEntry">Обновить проводку</v-btn> 
                    <v-btn v-else @click="handleSaveEntry">Сохранить проводку</v-btn> 
                </v-col>
            </v-row>
        </v-form>
        
</template>

<script>
import DateField from '@/components/elements/DateField'
import RegisterSelectField  from '@/components/register/RegisterSelectField';
import { loadDataToObject } from '@/modules/CommonUtils';
import { fundsDict, articlesDict, wiringTypeDirDict, wiringType } from '@/components/register/WireDict';
import {
  customerCounterpartiesRegisterConfig,
  supplierCounterpartiesRegisterConfig,
  supplierContractsRegisterConfig,
  customerContractsRegisterConfig,
  allEmployeesRegisterConfig
} from '@/components/register/RegisterConfigs';
import AddProjectDialog from '@/components/projects/AddProjectDialog.vue';
import { wPost, wUpdate } from '@/modules/Wirings';
import { projectKeyHolderNew } from '@/modules/KeysHolder.js';
import dayjs from 'dayjs'

const paymentMethods = [
                  { id: 1, text: 'Наличный', value: 'cash' },
                  { id: 2, text: 'Безналичный', value: 'cashless' },
            ];
export default {
    name: 'WiringForm',
    components: { DateField, RegisterSelectField, AddProjectDialog },
    props: ['project', 'oldWData', 'notSaveWiring'],
    data() {
        return {
            loadData: loadDataToObject,
            project_ID: this.oldWData?.data?.project.id,
            contract_ID: this.oldWData?.data?.contract?.id || null,
            projectInfo: this.project,
            selectedContract: null,

            ourOrgs: [],
            experts: [],
            counterpartiesRegisterConfig: {
                tabs: [
                {
                    title: 'Заказчики',
                    config: customerCounterpartiesRegisterConfig,
                },
                {
                    title: 'Подрядчики',
                    config: supplierCounterpartiesRegisterConfig,
                },
                {
                    title: 'Сотрудники',
                    config: allEmployeesRegisterConfig,
                }
                ],
            },

            wData: this.oldWData?.data || {
                sum: null,
                project: {
                    id: this.project?.id,
                    name: this.project?.shortName
                },
                type: null,
                paymentMethod: paymentMethods[0],
                paymentDate: null,
                expert: null,
                budgetYear: this.project?.year,
                organization: this.project?.customerContracts?.length ? this.project?.customerContracts[0].supplier : null,
                typeDir: null,
                fund: {},
                article: {},
                contractor: null,
                contract: null,
                accountNumber: null,
                contribution: null,
                note: null
            },

            typesItems: Object.entries(wiringType).map(([value, label]) => ({label, value})),
            articlesDict,
            
            paymentMethods,
            dialog: false,
            withoutProject: !this.project
        }
    },

    methods: {
        checkBindArticleByContract(article) {
            const bindContractArticlesArray = Object.values(this.articlesDict).flat(1).filter(article => article?.bind?.includes('contract')).map(({article}) => article);
            return bindContractArticlesArray.includes(article)
        },
        checkBindArticleByAgent(article) {
            const bindAgentArticlesArray = Object.values(this.articlesDict).flat(1).filter(article => article?.bind?.includes('agent')).map(({article}) => article);
            return bindAgentArticlesArray.includes(article)
        },
        async handleUpdateEntry() {
            if(!this.$refs.form.validate()) return;
            this.apiLoading = true;
            const res = await wUpdate(this.oldWData.id, this.wData.budgetYear, JSON.stringify(this.wData));
            this.apiLoading = false;
            if(res) {
                this.$emit('wiringSaved');
            }
        },
        async handleSaveEntry() {
            if(!this.$refs.form.validate()) return;
            const today = dayjs().format('YYYY-MM-DD');
            this.wData.createDate = today;
            await wPost(this.wData.budgetYear, JSON.stringify(this.wData))
            this.$emit('wiringSaved');

        },
        handleAddWiring() {
            if(!this.$refs.form.validate()) return;
            this.$emit('select', this.wData)
        },
        getArticlesItems(fundID) {
            return this.articlesDict[fundID].filter((article) => article[this.wData.paymentMethod?.value])
        },
        addFundAndArticle(fundID, articleID) {
            this.wData.fund = this.filteredFundsByProject.find(({id}) => id === fundID);
            this.wData.article = this.getArticlesItems(fundID).find(({id}) => id === articleID);
        },
        selectProject(project) {
            this.projectInfo = project
            this.wData.project = {
                id: project?.id,
                name: project?.shortName
            }
        }
        
    },
    computed: {
        // check() {
        //     const bindContractArticles = Object.values(this.articlesDict).flat(1);
        //     console.log(bindContractArticles, 'bindContractArticles')
        //     return null
        // },
        filteredFundsByProject() {
            return fundsDict[this.wData.typeDir]?.filter(({project}) => project === !!this.projectInfo);
        },
        contractsRegisterConfig() {
            return {
                    tabs: [
                        { title: 'С заказчиками', config: this.getCustomerContractsRegisterConfigWithFilter },
                        { title: 'С подрядчиками', config: this.getSupplierContractsRegisterConfigWithFilter },
                    ]
                }
        },
        getSupplierContractsRegisterConfigWithFilter() {
            const config = Object.assign({}, supplierContractsRegisterConfig)
            if(this.projectInfo) {
                const projectContractsID = this.projectInfo.supplierContracts.concat(this.projectInfo.customerContracts).map((contract) => contract.id)
                config.customFilterFunction = function(item) {
                    if(projectContractsID.includes(item.id)) return item
                    return false
                }
            }
            return config
        },
        getCustomerContractsRegisterConfigWithFilter() {
            const config = Object.assign({}, customerContractsRegisterConfig)
            if(this.projectInfo) {
                const projectContractsID = this.projectInfo.customerContracts.concat(this.projectInfo.supplierContracts).map((contract) => contract.id)
                config.customFilterFunction = function(item) {
                    if(projectContractsID.includes(item.id)) return item
                    return false
                }
            }
            return config
        },
        filteredTypeDirItems() {
            const filteredByProject =  Object.entries(wiringTypeDirDict).filter(([, { project }]) => project === !!this.projectInfo);
            const filteredByPaymentMethod = filteredByProject.filter(([, item]) => item[this.wData.paymentMethod?.value]);
            const res = filteredByPaymentMethod.map(([value, { title }]) => ({title, value}));
            return res
        },
        yearsList() {
            const year = new Date().getFullYear() + 5;
            const years = Array(10).fill().map((_, i) => year - i);
            return years.reverse();
        }
    },
    
    watch: {
        'withoutProject': function() {
            if(!this.withoutProject) {
                this.projectInfo = this.project;
                this.wData.project = {
                    id: this.project?.id,
                    name: this.project?.shortName
                };
            } else {
                this.projectInfo = null;
                this.wData.project = null;
            }
        },
        'projectInfo': function() {
            this.selectedContract = null;
            this.wData.budgetYear = this.projectInfo?.year
        },
        'wData.paymentMethod': function() {
            this.wData.fund = {};
            this.wData.article = {};
        },
        'wData.typeDir': function() {
            this.wData.fund = {};
            this.wData.article = {};
        },
        'projectInfo.isOurOrgSupplierContracts': function() {
            this.wData.organization = this.projectInfo?.customerContracts?.length ? this.projectInfo?.customerContracts[0].supplier : null
        },
        'selectedContract': function() {
            this.wData.contract = {
                id: this.selectedContract?.id,
                number: this.selectedContract?.contractNumber
            }
        }
    },
    async beforeMount() {
        await Promise.all([
            this.loadData(
                '/supmain/legalentities?page=0&size=100&sort=id,asc&search=isOurOrg:true',
                'ourOrgs',
                true
            ), 
            this.loadData(
                // '/supmain/experts?page=0&size=100&sort=id,asc&search=isRoleOffer:true',
                '/supmain/experts?page=0&size=100&sort=id,asc',
                'experts',
                true
            )
        ])
        this.wData.expert = this.experts?.find((expert) => expert.id === this.$user.id);
        
        if(!this.project && this.project_ID) {
            await this.loadData(
                // '/supmain/experts?page=0&size=100&sort=id,asc&search=isRoleOffer:true',
                `/supmain/projects/${this.project_ID}`,
                'encodedProjectInfo',
                true
            )
            if (!projectKeyHolderNew.checkKey()) return;
            this.projectInfo = await projectKeyHolderNew.decode(
                this.encodedProjectInfo.data,
            );
        }

        if(!this.selectedContract && this.contract_ID) {
            await this.loadData(
                // '/supmain/experts?page=0&size=100&sort=id,asc&search=isRoleOffer:true',
                `/supmain/contracts/${this.contract_ID}`,
                'contract',
                true
            );
            this.selectedContract = this.contract
        }
    }
}
</script>